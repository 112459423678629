<template>
    <div id="main">
        <el-container>
            <el-container class="container">
                <el-aside width="100px;" class="aside">
                    <div class="upper">
                        <img :src="iconUrl">
                    </div>
                    <div class="linker"  >
                        <div>
                            <router-link to="/main/goods" v-if="!isHr">商品</router-link>
                        </div>
                        <div>
                            <router-link to="/main/customer" v-if="!isHr">客户</router-link>
                        </div>
                        <div>
                            <router-link to="/main/orders" v-if="!isHr">订单</router-link>
                        </div>
                        <div>
                            <router-link to="/main/user" >客服</router-link>
                        </div>
                        <div>
                            <router-link to="/main/seller" >销售员</router-link>
                        </div>
                        <div class="fixbox">
                            <div class="info info-text" >{{companyName}}</div>
                        </div>
                    </div>
                </el-aside>
                <el-main class="mn"><router-view/></el-main>
            </el-container>
        </el-container>

    </div>
</template>
<script>
    export default {
        name:"mn",
        data(){
            return {
                lastOperateTime: new Date().getTime(),
                iconUrl: require("../assets/logo1.png"),
                countDesc:null
            }
        },
        computed:{
            companyName() {
                return localStorage.getItem("companyName");
            }
        },

        mounted() {
            document.onmousemove = () => {
                this.lastOperateTime = new Date().getTime(); //更新操作时间
            };
            setInterval(() => {
                this.countDesc=new Date().getTime() - this.lastOperateTime;
                //校验前端是否30分钟内未操作
                if (this.countDesc> 1000 *60 * 30) {
                    this.onLogout();
                    return;
                }
            }, 5000  );
        },
        methods:{
            onLogout(){
                this.$http.get("/api/logout").then(rsp=>{
                    location.href="/";
                })
            }
        }
    }
</script>
<style>

    .header{
        background: #42b983;
        overflow: auto;
    }

    .aside{
        position: relative;
        border-right: 1px solid #eeeeee;
    }
    .upper{
        height: 100px;
        background: #FF9D0E;
    }
    .mn{
        padding: 0;
        background: #eeeeee;
    }
    .linker{
        text-align: center;
        padding-top: 12px;
        font-size: 24px;
        position: relative;
    }
    .fixbox{
        /*width: 100%;*/
        position:fixed;
        bottom: 10px;
        left:0px;
        text-align: center;
    }
    .info-text{
        width: 100px;
        font-size:16px;
        height: auto;
        line-height: 16px;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow:ellipsis !important;
        color: #888888;
        text-decoration: none;
    }
    .linker > div{
        margin: 24px auto;
    }
    .linker a {
        color: #888888;
        text-decoration: none;
    }

    .linker a.router-link-exact-active {
        color: #FF9D0E;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td{
        background: #FFEEE2;
    }
    .el-table__header{
        font-size: 20px;
        font-weight: lighter;
        font-family: "Microsoft YaHei";
    }
    .el-table__body{
        font-size:20px;
    }
    /*.el-pager li.active{*/
    /*    color:#FF9D0E*/
    /*}*/
    /*.el-select-dropdown__item.selected{*/
    /*    color: #FF9D0E;*/
    /*}*/
    /*.el-pagination__sizes .el-input .el-input__inner{*/
    /*    border-color: #FF9D0E;*/
    /*}*/
    /*.el-pagination__sizes .el-input .el-input__inner:hover{*/
    /*    border-color: #FF9D0E;*/
    /*}*/
    /*.el-select .el-input.is-focus .el-input__inner{*/
    /*    border-color: #FF9D0E;*/
    /*}*/
    .mt  .el-button{
        font-size: 20px;
        padding-top: 10px ;
        padding-bottom: 10px;
    }
    .el-input,.el-form-item__label{
        font-size: 20px;
    }
    .el-date-editor .el-range-input, .el-date-editor .el-range-separator{
        font-size: 20px;
    }
    .mn .pager{
        display: flex;justify-content: center;margin-top: 20px;font-size: 20px;
    }
    .mt  .el-form-item{
        margin-bottom: 0px;
    }


</style>
